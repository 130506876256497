import { writable } from "svelte/store";
import {
  listItems,
  createItem,
  deleteItem,
  patchItem,
  updateProductsOrder,
} from "./api.js";

/*
 * Data store factory function + helper
 */
export const isLoading = writable(false);
export const isError = writable(false);
export const isSuccess = writable(false);
export const errors = writable({});

function createItemsStore() {
  const { subscribe, set, update } = writable({
    room: [],
    activity: [],
    dish: [],
    supplier: [],
    product: [],
  });

  return {
    subscribe,
    fetchItems: async (itemType, businessCode) => {
      // tell components store is loading and call API
      isLoading.set(true);
      let data;
      try {
        data = await listItems(itemType, businessCode);
      } catch (error) {
        isError.set(true);
        isLoading.set(false);
        return;
      }

      update((items) => {
        items[itemType] = items[itemType].concat(data.results);
        return items;
      });
      isLoading.set(false);
    },
    deleteItem: async (itemType, businessCode, id) => {
      try {
        await deleteItem(itemType, businessCode, id);
      } catch (error) {
        isError.set(true);
        isLoading.set(false);
        return;
      }

      update((items) => {
        items[itemType] = items[itemType].filter((item) => item.id != id);
        return items;
      });
    },
    addItem: async (itemType, businessCode, form) => {
      // tell components store is loading and call API
      isLoading.set(true);
      let data;
      try {
        data = await createItem(itemType, businessCode, form);
      } catch (error) {
        // DRF returns a 400 status code for validation errors
        if (error.status == 400) errors.set(error.data);
        isError.set(true);
        isLoading.set(false);
        return;
      }
      // set new == true, for gallery animation control
      data.new = true;

      update((items) => {
        items[itemType] = items[itemType].concat([data]);
        return items;
      });
      isLoading.set(false);
      isSuccess.set(true);
    },
    updateItem: async (itemType, businessCode, id, form) => {
      // tell components store is loading and call API
      isLoading.set(true);
      let data;
      try {
        data = await patchItem(itemType, businessCode, id, form);
      } catch (error) {
        // DRF returns a 400 status code for validation errors
        if (error.status == 400) errors.set(error.data);
        isError.set(true);
        isLoading.set(false);
        return;
      }

      update((items) => {
        // drop old item and concat new
        items[itemType] = items[itemType].filter((item) => item.id != id);
        items[itemType] = items[itemType].concat([data]);
        return items;
      });
      isLoading.set(false);
      isSuccess.set(true);
    },
  };
}

export const items = createItemsStore();

/*
 * Modal controls
 */
export const editItemModalIsOpen = writable(false);
export const bookItemModalIsOpen = writable(false);

const currentItemStoreStructure = {
  id: "",
  title: "",
  activity_type: "",
  room_type: "",
  minimum_price_currency: "",
  minimum_price: "",
  duration: "",
  duration_class: "",
  maximum_capacity: "",
  description: "",
  image_link: "",
  image_gallery: "",
  book_now_url: "",
  item_category: "",
  business_profile: "",
  pricing_text_line: "",
  description: "",
  item_url: "",
};

function createCurrentItemStore() {
  const { subscribe, set, update } = writable(currentItemStoreStructure);

  return {
    subscribe,
    set,
    setBusinessCode: (businessCode) => {
      set(currentItemStoreStructure);
      update((item) => {
        return { ...item, business_profile: businessCode };
      });
    },
  };
}

export const currentItem = createCurrentItemStore();

// "Book Now" modal div, rendered on certain pages
const bookingFooter = document.querySelector(".booking-footer");

export const notifyModalOpen = (modalType, item) => {
  // store the current item for components to use
  // note this must happen *prior* to opening the modal!
  if (item) currentItem.set(item);

  // clear any preexisting errors, these are no longer relevant
  errors.set({});

  switch (modalType) {
    case "edit":
      editItemModalIsOpen.set(true);
      break;
    case "book":
      bookItemModalIsOpen.set(true);
      break;
  }
  // hide "Book Now" div on modal open
  if (bookingFooter) {
    bookingFooter.style.visibility = "hidden";
  }
};

export const notifyModalClose = () => {
  // close all modals, we don't care which one is open
  editItemModalIsOpen.set(false);
  bookItemModalIsOpen.set(false);
  // reset context, has to be done *after* modals are closed
  isAddNewItem.set(false);
  isSuccess.set(false);
  isError.set(false);
  // show "Book Now" div on modal close
  if (bookingFooter) {
    bookingFooter.style.visibility = "visible";
  }
};

export const notifyBookingSuccess = () => currentItem.set(currentItemStoreStructure);

/*
 * Store helpers + functions
 */
export const isEdit = writable(false);
export const isBasicProfile = writable(false);
export const currentItemType = writable("");
export const isAddNewItem = writable(false);

export const notifyIsEdit = () => isEdit.set(true);
export const notifyIsBasicProfile = () => isBasicProfile.set(true);
export const notifyTypeChange = (type) => currentItemType.set(type);
export const notifyIsAddNewItem = (businessCode) => {
  isAddNewItem.set(true);
  currentItem.setBusinessCode(businessCode);
};

/*
 * Utility functions and constants
 */

const itemTypeToName = {
  activity: "activity",
  room: "room",
  dish: "menu item",
  product: "product",
  supplier: "supplier",
};

export const getItemNameByType = (itemType) => {
  return itemTypeToName[itemType];
};

function createProductsOrderStore() {
  const { subscribe, set, update } = writable([]);

  return {
    subscribe,
    updateOrder: async (businessCode, updatedList) => {
      isLoading.set(true);
      let data;
      try {
        data = await updateProductsOrder(businessCode, updatedList);
      } catch (error) {
        isError.set(true);
        isLoading.set(false);
        return;
      }

      set(data.results);
      isLoading.set(false);
      isSuccess.set(true);
    },
  };
}

export const productsOrderStore = createProductsOrderStore();
